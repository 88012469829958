import { doHealthCheck } from '@/healthcheck';
import type { RouteLocationNormalized } from 'vue-router';
import { request } from './helpers';

export async function oAuthApi(to: RouteLocationNormalized){
    return await request('get', '/auth/callback', to.query).then(res => res.json())
}


export async function validateJWT(jwt: string){
    await doHealthCheck()
    return request('get', '/auth/validate', {}, jwt).then(a => a.json()).catch(() => true)
}
