import { root } from '@/config'
import { auth } from '@/stores/auth'
import { AvailableModels } from '@/stores/model.js'
import { request } from './helpers'
/**
 * Api service
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const API = {
  req(method: 'get' | 'post' | 'put' | 'patch' | 'delete', path: string, data?: Record<string, any>|undefined) {
    return request(method, path, data, auth.jwt_token)
  },
  get(path: string, data?: Record<string, any>|undefined) {
    return this.req('get', path, data).then(res => res.json())
  },
  post(path: string, data?: Record<string, any>|undefined) {
    return this.req('post', path, data).then(res => res.json())
  },
  put(path: string, data?: Record<string, any>|undefined) {
    return this.req('put', path, data).then(res => res.json())
  },
  patch(path: string, data?: Record<string, any>|undefined) {
    return this.req('patch', path, data).then(res => res.json())
  },
  delete(path: string, data?: Record<string, any>|undefined) {
    return this.req('delete', path, data).then(res => res.json())
  },
  generateSubscriptionPage(){
      return this.post("/payments/subscription")
  },
  getNamespaces() {
    return this.get("/questionCategories")
  },
  async question(args: {
    messages?: {role: string, content: string}[], 
    namespace?: string,
    content?: string,
    conversation?: string, 
    forceNewNS?: boolean, 
    model?: AvailableModels,
    allowTools?: boolean,
    redact?: boolean,
  },callback?: (data: any) => any){ //'user'|'assisstant'
      const res = await this.req("post","/question", {...args, stream: !!callback})
      console.log(res.headers, res.headers.get("x-stream-id"))
      if (res.headers.has("x-stream-id")) {
        const stream = new EventSource(root + "/sse/" + res.headers.get("x-stream-id"))
        stream.onmessage = (e) => {
          callback?.(JSON.parse(e.data))
        }
      }
      return res.json();

  },
  feedback(question_id: string, feedback: 'like'|'dislike'){
      return this.patch("/feedback", { question_id, feedback })
  },
  stripeUrl(plan: "normal"|"enterprise", coupon: string){
      return this.post("/payments/create-checkout-session", {plan, coupon}).catch(() => undefined)
  },
  getProfileData(){
      return this.get("/management/users")
  },
  patchProfileData(data: any){
    return this.patch("/management/users", data)
  },
  generateApiKey(){
      return this.patch("/management/api-key").then(({api_key}) => api_key)
  },
  fetchApiKey(){
      return this.get("/management/api-key").then(({api_key}) => api_key)
  },
  addUser(email: string){
      return this.post("/management/users", {email})
  },
  generateReport(start_date: string, end_date: string, locale: string){
      return this.req("post", "/management/reports", {start_date, end_date, locale}).then(res => res.blob())
  },
  editUser(uid: string, method: "ban"|"unban"|"delete"|"promote"|"demote" | "startredacting" | "stopredacting"){
      if (method === "delete"){
          return this.delete(`/management/users/${uid}`)
      }
      return this.patch(`/management/users/${uid}`, {method})
  },
  deleteConversations(id: string|undefined){
    return this.delete(id ? `/conversations/${id}`: '/conversations').then(() => []).catch(() => undefined)
  },
  fetchConversation(id: string){
    return this.get(`/conversations/${id}`).catch(() => [])
  },
  fetchUsage(){
    return this.get(`/billing/usage`).then(({quantity}) => quantity).catch(() => 0)
  },
  getConversations(){
    return this.get('/conversations').then(({conversations}) => conversations).catch(() => [])
  },
  getConversationInfo(id: string){
    return this.get(`/conversations/${id}/info`).then(({conversation}) => conversation).catch(() => [])
  },


  getTemplates() {
    return this.get('/templates').catch(() => [])
  },
  newTemplate(title: string, text: string) {
    return this.post('/templates', {title, text})
  },
  editTemplate(id: string, title:string, text: string) {
    return this.patch(`/templates/${id}`, {title, text})
  },
  deleteTemplate(id: string) {
    return this.delete(`/templates/${id}`)
  },



  getOrg() {
    return this.get('/management/msp')
  },
  getCustomers() {
    return this.get('/management/msp/customers').catch(() => [])
  },
  addCustomer(org: {
    name: string,
    billingType: "fixedUser" | "consumption",
    maxSubDepth: number,
  }) {
    return this.post('/management/msp/customers', org)
  },
  updateCustomer(org: string, data: { active?: boolean, address?: string, postal?: string, country?: string; phone?: string; email?: string; legalName?: string;}) {
    return this.patch(`/management/msp/customers/${org}`, data)
  },
  getOrgUsers(org: string) {
    return this.get(`/management/msp/customers/${org}/users`).catch(() => [])
  },
  addOrgUser(org: string, email: string){
    return this.post("/management/msp/customers/" + org + "/users", {email})
  },
  editOrgUser(org: string, uid: string,data: { role?: "lead" | "company user", active?: boolean }){
    return this.patch(`/management/msp/customers/${org}/users/${uid}`, data)
  },
  deleteOrgUser(org: string, uid: string){
    return this.delete(`/management/msp/customers/${org}/users/${uid}`)
  },
  getInvoices(org: string) {
    return this.get('/management/msp/invoices/' +org).catch(() => [])
  },
  getInvoice(org: string, id: string) {
    return this.get('/management/msp/invoices/' + org + '/' + id).catch(() => [])
  },
  sendXeroInvoice(org: string, id: string) {
    return this.post('/management/msp/invoices/' + org + '/' + id + "/xero")
  },
  getMSPApplicants() {
    return this.get('/management/msp/applications').catch(() => [])
  },
  deleteMspApplicant(id: string) {
    return this.delete('/management/msp/applications/' + id)
  },
  purgeUser(id: string) {
    return this.post('/dlp/purge/' + id)
  },
  exportUser(id: string) {
    return this.post('/dlp/export/' + id)
  },
  getRedactionJob() {
    return this.get('/dlp/redact').then(a => a.job)
  },
  redactUser(id: string) {
    return this.post('/dlp/redact/' + id)
  }
}
