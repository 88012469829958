import type { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

/**
 * Create component route
 */
export function route (path: string, view: string): RouteRecordRaw {
  return {
    path,
    component: () => import(`../views/${view}View.vue`)
  }
}

/**
 * Create hook route
 */
export function hook(path: string, callback: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void): RouteRecordRaw {
  return {
    path,
    component: { render: () => null },
    beforeEnter: callback
  }
}
