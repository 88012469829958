import { request } from './services/helpers';

let dialog: HTMLDialogElement | true | null = null;

function tryShowDialog() {
    try {
        dialog = document.createElement('dialog')
        dialog.innerHTML = `
            <h3>Connecting to EtherAssist...</h3>
            <p>Looks like it's taking longer than usual to connect to EtherAssist.</p>
            <p>We'll keep trying but in the meantime you can <a class='purple' target="_blank" href="https://status.efficientether.co.uk/">check our status page</a>.</p>
            <div class="loader" id="loader" style="display:flex">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        `
        document.body.appendChild(dialog)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - compiler doesn't know about showModal
        dialog.showModal()
    } catch(e) {
        console.error(e)
        if (confirm("Looks like it's taking longer than usual to connect to EtherAssist. Would you like to check our status page?")) 
            location.href = "https://status.efficientether.co.uk/"
        dialog = true;

    }
}

export async function doHealthCheck() {
    // check that the backend is actually online before
    // trying to validate the jwt - this prevents users
    // getting logged out/seeing the 'you are banned' page
    let i = 0;
    const time = Date.now()
    let canShowDialog = true
    while (
        await request('get', "/api/healthcheck")
            .then(a => a.json())
            .then(a => a.status !== "healthy")
            .catch(() => true)
    ) {
        console.warn("Waiting for backend to be ready")
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const sufficientTimeElapsed = i > 3 || Date.now() - time > 5000;
        if (dialog) canShowDialog = false
        if (sufficientTimeElapsed && canShowDialog) 
            tryShowDialog()
        
        i++
    }
    if (dialog) {
        if (dialog instanceof HTMLElement) dialog.remove()
        dialog = null
    }
}