import { oAuthApi, validateJWT } from '@/services/auth';
import { computed, reactive, ref, watch } from 'vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';


type UserRole = 'newbie' | 'personal' | 'lead' | 'company user' | 'company';
interface User {
    id: string;
    display_name: string;
    show_onboarding: boolean;
    is_active: boolean;
    subscription_status: boolean;
    azr_saas_status: boolean;
    role: UserRole;
    jwt: string;
    isGlobalAdmin?: true;
    subscription: {
        status: "active" | "pending" | "inactive";
        method: "stripe" | "azure" | "complimentary" | "inherited" | "none";
        type: "fixedUser" | "consumption";
        mode: "direct" | "new";
        isProvider: boolean;
    };
    org_id?: string;
    notification_seen: string;
    autoredact?: boolean;
}


export const initialised = ref(false)
export const account = ref<User | null>(null)
export const error = ref<string | null>()


async function oAuthCallback(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    initialised.value = true
    error.value = null
    console.log(document.cookie)
    try{
        let response
        if(!to.query?.token){
            response = await oAuthApi(to)
        }else{
            response = await validateJWT((to.query?.token ?? "") as string)
        }

        if(!response || !response.jwt){
            throw new Error("Invalid Token please reconnect")
        }
        await set_account(response as User)
        const redirect = sessionStorage.getItem("redirect") || '/dashboard/';
        sessionStorage.removeItem("redirect")
        console.log({redirect});
        next(redirect)
    }catch(err){
        console.error(err)
        error.value = (err as Error)?.message as string
        next('/')
    }

}

async function onboarding(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    try{
        if(!to.query?.token){
            throw new Error("Can't confirm transaction")
        }
        const response = await validateJWT((to.query?.token ?? "") as string) as User
        console.log({response})
        if(!response){
            throw new Error("Can't confirm transaction")
        }
        await set_account(response)
        if(['personal', 'company user'].includes(response.role)){
            next('/dashboard/')
        }else{
            next('/dashboard/profile')
        }
    }catch(err){
        console.error(err)
        error.value = (err as Error)?.message as string
        next('/dashboard/subscribe')
    }
}
const is_authenticated = computed(() => {
    return initialised.value && !!account.value
})

const is_active = computed(() => {
    return  account.value?.is_active
})

const subscription = computed(() => {
    return  account.value?.subscription
})


const subscription_status = computed(() => {
    return  account.value?.subscription_status || account.value?.azr_saas_status
})

const is_lead = computed(() => {
    return !!account.value && ['company', 'lead'].includes(account.value.role)
})

const is_subscriber = computed(() => {
    return !!account.value && ['company', 'personal'].includes(account.value.role)
})

const jwt_token = computed(() => {
    return account.value?.jwt ?? ""
})

const notification_seen = ref(new Date(0))
watch(() => account.value, (user) => {
    if(user) notification_seen.value = new Date(user.notification_seen)
})

async function set_account(user: User): Promise<boolean> {//|null
    account.value = user
    console.log({user, v: account.value, j: account.value.jwt})
    localStorage.setItem("user", JSON.stringify({jwt: user.jwt}))
    if(user.show_onboarding){
        return true
    }
    return false
}

async function initialize(): Promise<boolean>{
    initialised.value = true
    const user = JSON.parse(localStorage.getItem('user') ?? '""') as User
    let usern
    if (user && user.jwt && (usern = await validateJWT(user.jwt))){
        return await set_account(usern as User)
    }
    return false
}

async function logout (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    account.value = null
    localStorage.removeItem('user')
    next('/')
}


export const auth = reactive({
  error,
  initialised,
  is_authenticated,
  initialize,
  jwt_token,
  notification_seen,
  account,
  logout,
  oAuthCallback,
  onboarding,
  is_active,
  is_lead,
  is_subscriber,
  subscription_status,
  subscription,
  org_id: computed(() => account.value?.org_id),
  autoredact: computed(() => !!account.value?.autoredact)
})
