import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import Popper from "vue3-popper"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBan, faBroom, faCaretDown, faCaretUp, faEllipsisVertical, faFileExport, faPenToSquare, faShield, faShieldHalved, faThumbsDown, faThumbsUp, faTrash, faUserAlt, faUserSlash } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faThumbsDown, faThumbsUp, faTrash, faUserSlash, faUserAlt, faCaretUp, faCaretDown, faBan, faPenToSquare, faBroom, faFileExport, faShield, faShieldHalved, faEllipsisVertical)

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.js"
import './assets/scss/main.scss'
import { doHealthCheck } from './healthcheck'



doHealthCheck().then(() =>{
  createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component("Popper", Popper)
    .use(router)
    .use(VueMonacoEditorPlugin)
    .mount('#app')


})

