import { root } from '@/config'
/**
 * Fetch wrapper
 */

export async function request (method: 'get' | 'post' | 'put' | 'patch' | 'delete', path: string, data?: any, token?: string) {
  const options: RequestInit = {
    method: method.toUpperCase(),
    headers: {
        ...(token ? {'Authorization': `Bearer ${token}`} : {}),
        'Content-Type': 'application/json',
    },
  }
  if (data) {
    method === 'get'
      ? path += `?${new URLSearchParams(data).toString()}`
      : options['body'] = JSON.stringify(data)
  }

  return fetch(`${root}${path}`, options)
    .then(response => {
      if (response.status == 401){
          localStorage.clear()
          location.href = '/login'
      }
      return response;
    })
}

export const CustomerTypes = ["Customer", "MSP", "Distributor", "Root"];