import { API } from '@/services/api'
import { auth } from '@/stores/auth'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { hook } from './helpers'

const unguarded = [
  '/',
  '/msp',
  '/auth/callback',
  '/dashboard/terms-condition',
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "home" */ '../views/LoginView.vue')
  },
  {
    path: '/msp',
    name: 'MSPLogin',
    component: () => import(/* webpackChunkName: "home" */ '../views/MSPLoginView.vue')
  },
  {
    path: "/saas_sub",
    name: "RegisterAzrSaas",
    component: () => import(/* webpackChunkName: "azrsaas" */ '../views/saasSub.vue')
  },
  {
    path: '/dashboard',
    name: 'DashboardMain',
    component: () => import('../views/dashboard/DashboardMain.vue'),
    children: [
      {
        path: 'profile',
        name: 'ProfileView',
        component: () => import('../views/dashboard/ProfileView.vue'),
      },
      {
        path: 'templates',
        name: 'TemplateView',
        component: () => import('../views/dashboard/TemplateView.vue'),
      },
      {
        path: 'subscribe',
        name: 'SubscriptionView',
        component: () => import('../views/dashboard/SubscriptionView.vue'),
      },
      {
        path: 'terms-condition',
        name: 'TermsCondition',
        component: () => import('../views/dashboard/TermsCondition.vue'),
      },
      {
        path: 'third-party-software',
        name: 'ThirdParty',
        component: () => import('../views/dashboard/ThirdParty.vue'),
      },
      {
        path: 'security',
        name: 'SecurityView',
        component: () => import('../views/dashboard/SecurityView.vue'),
      },
      {
        path: 'inactive',
        name: 'InactiveView',
        component: () => import('../views/dashboard/InactiveView.vue'),
      },
      {
        path: 'api',
        name: 'ApiView',
        component: () => import('../views/dashboard/ApiView.vue'),
      },
      {
        path:        '',
        alias:  'c/:id',
        name: 'ChatView',
        component: () => import('../views/dashboard/ChatView.vue'),
      },
    ]
  },
  {
    path: '/msp/dashboard',
    name: 'MSPDashboardMain',
    redirect: { path: "/msp/dashboard/companies" },
    component: () => import('../views/dashboard/MSPDashboardMain.vue'),
    children: [
      {
        path: 'profile',
        name: 'MSPProfileView',
        component: () => import('../views/dashboard/ProfileView.vue'),
      },
      {
        path: 'companies',
        name: 'MSPPortalView',
        component: () => import('../views/dashboard/MSPPortal.vue'),
      },
      {
        path: 'companies/:id',
        name: 'MSPViewCompany',
        component: () => import('../views/dashboard/MSPCompany.vue'),
      },
      {
        path: 'subscribe',
        name: 'MSPSubscriptionView',
        component: () => import('../views/dashboard/SubscriptionView.vue'),
      },
      {
        path: 'terms-condition',
        name: 'MSPTermsCondition',
        component: () => import('../views/dashboard/TermsCondition.vue'),
      },
      {
        path: 'channel-programme',
        name: 'MSPChannelProgramme',
        component: () => import('../views/dashboard/ChannelProgram.vue'),
      },
      {
        path: 'third-party-software',
        name: 'MSPThirdParty',
        component: () => import('../views/dashboard/ThirdParty.vue'),
      },
      {
        path: 'security',
        name: 'MSPSecurityView',
        component: () => import('../views/dashboard/SecurityView.vue'),
      },
      {
        path: 'inactive',
        name: 'MSPInactiveView',
        component: () => import('../views/dashboard/InactiveView.vue'),
      },
      {
        path: "billing",
        name: "MSPBilling",
        component: () => import('../views/dashboard/MSPInvoiceList.vue'),
      },
      {
        path: "billing/:org/:id",
        name: "MSPBill",
        component: () => import('../views/dashboard/MSPInvoice.vue'),
      },
      {
        path: "applicants",
        name: "MSPApplicatants",
        component: () => import('../views/dashboard/MSPApplications.vue'),
      },
    ]
  },
  hook('/logout', auth.logout),
  hook('/auth/callback', auth.oAuthCallback),
  hook('/dashboard/subscribe/success', auth.onboarding),
  hook('/dashboard/subscription', (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    API.generateSubscriptionPage().then(({ url }) => {
      if (url) {
        location.href = url
      }
      throw new Error("Try Again Later")
    }).catch(() => next("/dashboard"))
  }),
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// set up auth and guard routes
router.beforeEach(async (to, from, next) => {
  console.log("Going to " + to.path)
  if (to.path === "/dashboard/subscribe/success" || to.path==="/logout") {
    return next()
  }
  // guarded
  const guarded = unguarded.every(path => path !== to.path)
  console.log({
    guarded,
    is_authenticated: auth.is_authenticated,
    initialised: auth.initialised,
    to: to.path,
    params: to.params,
    too: to
  })
  if (!auth.initialised) {
    console.log("(!auth.initialised)")
    const redirect = await auth.initialize()
    
  }
  if (auth.is_authenticated && (!auth.account?.show_onboarding && (auth.subscription?.status != "active" || !auth.is_active))){
    if(!["/logout", "/dashboard/inactive", "/dashboard/subscription", "/saas_sub"].includes(to.path) || (to.path === "/dashboard/subscription" && !auth.is_subscriber)) {
      return next("/dashboard/inactive")
    }else{
      return next()
    }
  }
  // 404
  if (to.matched.length == 0) {
    console.log("(to.matched.length == 0)")
    // to.path = "/login"
    return next('/')
  }

  // unguarded
  if (!guarded) {
    if( to.path === "/" && auth.is_authenticated ){
      return next('/dashboard/')
    }
    console.log("(!guarded)")
    return next()
  }
  if (!auth.is_authenticated) {
    console.log("guarded && !auth.is_authenticated")
    sessionStorage.setItem("redirect", to.fullPath)
    return next('/')
  } else if (to.path === '/' || (to.path === '/dashboard/subscribe' && !auth.account?.show_onboarding)) {
    console.log("to.matched[0]?.path === '/login' && auth.is_authenticated")
    return next("/dashboard/")
  } else if (auth.account?.show_onboarding && to.path !== "/dashboard/subscribe" && to.path !== "/saas_sub") {
    console.log("auth.account.show_onboarding")
    return next("/dashboard/subscribe")
  } else if (to.path === "/dashboard/profile" && !(auth.account?.role === "company" || auth.account?.role === "lead")) {
    return next('/dashboard/')
  } else {
    console.log("auth.is_authenticated")
    return next()
  }

})


export default router
